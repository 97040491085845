// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
//import { colors } from 'react-select/dist/declarations/src/theme'

//const config = useJwt.jwtConfig

// const initialUser = () => {
//   const item = window.localStorage.getItem('userData')
//   //** Parse stored json or if none return initialValue
//   return item ? JSON.parse(item) : {}
// }
const initialUser={
  id:"",
  firstName:"",
  lastName:"",
  email:"",
  isEmailVerified:false,
  role:""

}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser,
    isLogin: false
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = {...action.payload}
      state.isLogin = true
     // state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
     // state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
     // localStorage.setItem('userData', JSON.stringify(action.payload))
      //localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
     // localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
    },
    handleLogout: state => {
      state.userData = {}
      state.isLogin= false
      // state[config.storageTokenKeyName] = null
      // state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      // localStorage.removeItem('userData')
      // localStorage.removeItem(config.storageTokenKeyName)
      // localStorage.removeItem(config.storageRefreshTokenKeyName)
    }
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
