import { createSlice } from '@reduxjs/toolkit';

const initialRecaptchaData = { 
    
   isRecaptchaLoaded:false

};
const recaptchaData=createSlice({
    name:'recaptchaData',
    initialState:initialRecaptchaData,
    reducers:{
        setRecaptchaLoaded(state,action){//payload: array
            state.isRecaptchaLoaded=true;
        }
    }
})

export const recaptchaDataActions=recaptchaData.actions;

export default recaptchaData.reducer;